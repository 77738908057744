import { defaults } from 'axios';

var selectedApiUrl = 'dev';

const apiUrlDev = 'http://localhost/api/';
const apiUrlProd = 'https://lead-funnel.myc3.com/php/api/';

const debugData = {
    id: 1,
    name: "Test Projekt",
    survey: {
        id:6,
        name:"eyyyy",
        information:7,
        phonenumber: "012345678910",
        pages:[
            {
                id:1,
                title:"awd awd awd awd debug",
                data:"<p>awd aw dawd awd awd awd</p>",
                elementType:1,
                elementData:[
                    {id:1,data:"quat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tell",icon:"check"},
                    {id:2,data:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",icon:"home"},
                    {id:3,data:"test",icon:"address-card"},
                    {id:4,data:"test",icon:"home"},
                    {id:5,data:"test",icon:"home"},
                    {id:6,data:"test",icon:"home"},
                    {id:7,data:"test",icon:"home"},
                    {id:8,data:"test",icon:"home"},
                    {id:9,data:"test",icon:"home"},
                ]
            },
            {
                id:2,
                title:"awd awd awd awd ",
                data:"<p>awdawdawd awdaw dawd</p>",
                elementType:2,
                elementData:[
                    {id:4,data:"test",icon:"check"},
                    {id:5,data:"test",icon:"home"}
                ]
            }
        ]
    }
};

function setApiUrl() {
    if (selectedApiUrl == 'dev')
        defaults.baseURL = apiUrlDev;
    else if (selectedApiUrl == 'prod')
        defaults.baseURL = apiUrlProd;
    else if (process.env.NODE_ENV == 'development')
        defaults.baseURL = apiUrlDev;
    else
        defaults.baseURL = apiUrlProd;

    defaults.withCredentials = true;
}

export { setApiUrl, debugData };