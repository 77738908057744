<template>
	<umfrage-tool :devdata="devdata" />
</template>

<script>
import '../../../../UmfrageWebComponent/dist/umfrage-tool';
export default {
	name: 'LiveDisplay',
	props: {
		project: Object,
		survey: Object
	},
	computed: {
		devdata: function () {
			return JSON.stringify({
				id: this.project.id,
				name: this.project.name,
				survey: this.survey
			});
		}
	}
};
</script>

<style></style>
